import React from 'react'
import { Helmet } from 'react-helmet'

import './datenschutz.css'

const datenschutz = () => {
	return (
		<>
			<Helmet>
				<title>
					Datenschutz | Betonschäden, Balkonschäden, Betonkosmetik, Balkone,
					Brücken, Brandschäden, Heidelberg, Mannheim - O+S Betonschutz
				</title>
			</Helmet>
			<div className="home-referenzen">
				<p className="home-referenzen-title">Datenschutz</p>
				<div className="home-referenzen-divider"></div>
				<div className="datenschutz-description">
					<div className="datenschutz-description-item">
						<p>
							<b>Haftungsausschluss:</b>
						</p>
						<br />
						<br />
						<p>
							<b>Haftung für Inhalte</b>
						</p>
						<br />
						<br />
						<p>
							Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
							Für die Richtigkeit, Vollständigkeit und Aktualität der
							Inhaltekönnen wir jedoch keine Gewähr übernehmen. Als
							Diensteanbieter sind wir gemäß § 7 Abs.1 TMG füreigene Inhalte auf
							diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
							§§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
							verpflichtet, übermittelte oder gespeicherte fremde Informationen
							zuüberwachen oder nach Umständen zu forschen, die auf eine
							rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
							oder Sperrung der Nutzung von Informationen nach den allgemeinen
							Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
							ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
							Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
							Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
						</p>
						<br />
						<p>
							<b>Haftung für Links</b>
						</p>
						<br />
						<br />
						<p>
							Unser Angebot enthält Links zu externen Webseiten Dritter, auf
							deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
							diese fremden Inhalte auch keine Gewähr übernehmen. Für die
							Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
							oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
							wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
							überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
							Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
							der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
							Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
							Rechtsverletzungen werden wir derartige Links umgehend entfernen.
						</p>
						<br />
						<p>
							<b>Urheberrecht</b>
						</p>
						<br />
						<p>
							Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
							diesen Seiten unterliegen dem deutschen Urheberrecht. Die
							Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
							Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
							schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
							Downloads und Kopien dieser Seite sind nur für den privaten, nicht
							kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
							Seite nicht vom Betreiber erstellt wurden, werden die
							Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
							Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
							Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
							entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
							werden wir derartige Inhalte umgehend entfernen.
						</p>
						<br />
						<br />
						<p>
							<b>Datenschutz</b>
						</p>
						<br />
						<p>
							Die Nutzung unserer Webseite ist in der Regel ohne Angabe
							personenbezogener Daten möglich. Soweit auf unseren Seiten
							personenbezogene Daten (beispielsweise Name, Anschrift oder
							E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
							stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
							ausdrückliche Zustimmung nicht an Dritte weitergegeben.
							<br />
							<br />
							Wir weisen darauf hin, dass die Datenübertragung im Internet
							(z.B.bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
							kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
							Dritte ist nicht möglich.
							<br />
							<br />
							Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
							Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
							angeforderter Werbung und Informationsmaterialien wird hiermit
							ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
							ausdrücklich rechtliche Schritte im Falle der unverlangten
							Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
						</p>
						<br />
						<br />
						<p>
							<b>
								Datenschutzerklärung für die Nutzung von Facebook-Plugins
								(Like-Button)
							</b>
						</p>
						<br />
						<p>
							Auf unseren Seiten sind Plugins des sozialen Netzwerks
							Facebook,1601 South California Avenue, Palo Alto, CA 94304, USA
							integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo
							oder dem “Like-Button” (“Gefällt mir”) auf unserer Seite. Eine
							Übersicht über die Facebook-Plugins finden Sie hier:{' '}
							<span>http://developers.facebook.com/docs/plugins/.</span>
							<br />
							<br />
							Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
							Verbindung zwischen Ihrem Browser und dem Facebook-Server
							hergestellt. Facebook erhält dadurch die Information, dass Sie mit
							Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook
							“Like-Button” anklicken während Sie in Ihrem Facebook-Account
							eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem
							Facebook-Profil verlinken. Dadurch kann Facebook den Besuch
							unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf
							hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
							der übermittelten Daten sowie deren Nutzung durch Facebook
							erhalten. Weitere Informationen hierzu finden Sie in der
							Datenschutzerklärung von facebook unter
							http://de-de.facebook.com/policy.php
							<br />
							<br />
							Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten
							Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte
							aus Ihrem Facebook-Benutzerkonto aus.
						</p>
						<br />
						<br />
						<p>
							<b>Datenschutzerklärung für die Nutzung von Google Analytics</b>
						</p>
						<br />
						<p>
							Diese Website benutzt Google Analytics, einen Webanalysedienst der
							Google Inc. (“Google”). Google Analytics verwendet sog. “Cookies”,
							Textdateien, die auf Ihrem Computer gespeichert werden und die
							eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
							durch den Cookie erzeugten Informationen über Ihre Benutzung
							dieser Website werden in der Regel an einen Server von Google in
							den USA übertragen und dort gespeichert. Im Falle der Aktivierung
							der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von
							Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
							oder in anderen Vertragsstaaten des Abkommens über den
							Europäischen Wirtschaftsraum zuvor gekürzt.
							<br />
							<br />
							Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
							von Google in den USA übertragen und dort gekürzt. Im Auftrag des
							Betreibers dieser Website wird Google diese Informationen
							benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
							die Websiteaktivitäten zusammenzustellen und um weitere mit der
							Websitenutzung und der Internetnutzung verbundene Dienstleistungen
							gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
							Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
							nicht mit anderen Daten von Google zusammengeführt.
							<br />
							<br />
							Sie können die Speicherung der Cookies durch eine entsprechende
							Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
							jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
							sämtliche Funktionen dieser Website vollumfänglich werden nutzen
							können. Sie können darüber hinaus die Erfassung der durch das
							Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
							(inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
							Daten durch Google verhindern, indem sie das unter dem folgenden
							Link verfügbare Browser-Plugin herunterladen und installieren:
							http://tools.google.com/dlpage/gaoptout?hl=de.
						</p>
						<br />
						<br />
						<p>
							<b>Datenschutzerklärung für die Nutzung von Google +1</b>
						</p>
						<br />
						<p>
							<b>Erfassung und Weitergabe von Informationen:</b>
						</p>
						<br />
						<p>
							Mithilfe der Google +1-Schaltfläche können Sie Informationen
							weltweit veröffentlichen. Über die Google +1-Schaltfläche erhalten
							Sie und andere Nutzer personalisierte Inhalte von Google und
							unseren Partnern. Google speichert sowohl die Information, dass
							Sie für einen Inhalt +1 gegebenhaben, als auch Informationen über
							die Seite, die Sie beim Klicken auf +1 angesehen haben. Ihre +1
							können als Hinweise zusammen mit Ihrem Profilnamen und Ihrem Foto
							in Google-Diensten, wie etwa in Suchergebnissen oder in Ihrem
							Google-Profil, oder an anderen Stellen auf Websites und Anzeigen
							im Internet eingeblendet werden.
							<br />
							<br />
							Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die
							Google-Dienste für Sie und andere zu verbessern. Um die Google
							+1-Schaltfläche verwenden zu können, benötigen Sie ein weltweit
							sichtbares, öffentliches Google-Profil, das zumindest den für das
							Profil gewählten Namen enthalten muss. Dieser Name wird in allen
							Google-Diensten verwendet. In manchen Fällen kann dieser Name auch
							einen anderen Namen ersetzen, den Sie beim Teilen von Inhalten
							über Ihr Google-Konto verwendet haben. Die Identität Ihres
							Google-Profils kann Nutzern angezeigt werden, die Ihre
							E-Mail-Adresse kennen oder über andere identifizierende
							Informationen von Ihnen verfügen.
							<br />
							<br />
							<b>Verwendung der erfassten Informationen:</b>
							<br />
							<br />
							Neben den oben erläuterten Verwendungszwecken werden die von Ihnen
							bereitgestellten Informationen gemäß den geltenden
							Google-Datenschutzbestimmungen genutzt. Google veröffentlicht
							möglicherweise zusammengefasste Statistiken über die
							+1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und Partner
							weiter, wie etwa Publisher, Inserenten oder verbundene Websites.
						</p>
						<br />
						<br />
						<p>
							<b>Datenschutzerklärung für die Nutzung von Twitter</b>
						</p>
						<br />
						<p>
							Auf unseren Seiten sind Funktionen des Dienstes Twitter
							eingebunden. Diese Funktionen werden angeboten durch die Twitter
							Inc., 795Folsom St., Suite 600, San Francisco, CA 94107, USA.
							Durch das Benutzen von Twitter und der Funktion “Re-Tweet” werden
							die von Ihnen besuchten Webseiten mit Ihrem Twitter-Account
							verknüpft und anderen Nutzern bekannt gegeben. Dabei werden auch
							Daten an Twitter übertragen.
							<br />
							<br />
							Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
							Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung
							durch Twitter erhalten. Weitere Informationen hierzu finden Sie in
							der Datenschutzerklärung von Twitter
							unterhttp://twitter.com/privacy.
							<br />
							<br />
							Ihre Datenschutzeinstellungen bei Twitter können Sie in den
							Konto-Einstellungen unterhttp://twitter.com/account/settings
							ändern.
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default datenschutz
