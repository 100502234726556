import React, { Component } from 'react'
import SlickSlider from 'react-slick'

class Slider extends Component {
	render() {
		const PrevArrow = props => {
			const { className, onClick } = props
			return (
				<div className={className} onClick={onClick}>
					<img
						className="home-slider-arrow"
						src="/images/home/referenzen/arrow_left.png"
						alt="slider-left-button"
					/>
				</div>
			)
		}

		const NextArrow = props => {
			const { className, onClick } = props
			return (
				<div className={className} onClick={onClick}>
					<img
						className="home-slider-arrow"
						src="/images/home/referenzen/arrow_right.png"
						alt="slider-right-button"
					/>
				</div>
			)
		}

		const settings = {
			prevArrow: <PrevArrow />,
			nextArrow: <NextArrow />,
			infinite: true,
			speed: 500,
			...this.props.settings
		}

		return (
			<SlickSlider {...settings}>
				{this.props.sliderPictureElements}
			</SlickSlider>
		)
	}
}

export default Slider
