import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './home.css'

class HomeLeistungen extends Component {
	state = {
		activeItemIndex: 0
	}

	createDisplayTexts = allText => {
		const currentText = allText[this.state.activeItemIndex]
		return (
			<>
				<div className="home-leistungen-slides-description-title">
					{currentText.title}
				</div>
				<div className="home-leistungen-slides-description-text">
					{currentText.description1.map(t => (
						<p key={t}>- {t}</p>
					))}
				</div>
				<div className="home-leistungen-slides-description-more">
					<Link to={currentText.url}>Erfahren Sie mehr</Link>
				</div>
			</>
		)
	}

	renderCurrentIndex = index => {
		this.setState({ activeItemIndex: index })
	}

	render() {
		const { activeItemIndex } = this.state
		const displayTexts = [
			{
				title: 'BETON- UND BALKONINSTANDSETZUNG',
				description1: [
					'Gerüststellung',
					'Betonsanierung',
					'Abbrucharbeiten',
					'Estriche',
					'Beschichtung',
					'Fliesenbeläge',
					'Schienen- und Rinnensysteme',
					'Balkongeländer',
					'Malerarbeiten',
					'Betonkosmetik'
				],
				url: '/leistungen/beton-und-balkoninstandsetzung'
			},
			{
				title: 'HORIZONTALSPERREN, BESCHICHTUNG, KELLERABDICHTUNG',
				description1: [
					'Bodenbeschichtung',
					'EP, PUR, PMMA',
					'Schleierinjektion',
					'Acrylatgele',
					'Spiralanker',
					'Rissverpressung',
					'Mauerwerksverfugung',
					'Abdichtung gegen drückendes Wasser',
					'Horizontalsperre für Mauerwerk',
					'Zementinjektionen',
					'KMB Abdichtung',
					'PMMA Anschlüsse'
				],
				url: '/leistungen/horizontalsperren-beschichtung-kellerabdichtung'
			},
			{
				title: 'STATISCHE SPRITZBETONVERSTÄRKUNGEN, MAUERWERK',
				description1: [
					'Spritzbeton nach DIN 18551, DIN EN 14487 für die Instandsetzung',
					'Druckluftstrahlen als Untergrundvorbehandlung',
					'Stahlbewehrung und Anker',
					'Spritzmörtel im Nass- und Trockenspritzverfahren',
					'Hochdruckwasserstrahlen',
					'Abfräsen von minderwertigen Untergründen',
					'Rissverpressung'
				],
				url: '/leistungen/statische-spritzbetonverstaerkungen'
			},
			{
				title: 'Brückensanierung und Korrosionsschutz',
				description1: [
					'Abstemmen und Entfernen von Schutzbeton und Belägen',
					'Abdichtung mit feuerfestem Harz',
					'Abdichtung nach ZTV-BEL-B',
					'Geländerarbeiten',
					'Schwerer Korrosionsschutz',
					'Beschichtung nach OS1 bis OS13 (OSA - OSF)',
					'Fugenbänder',
					'PU Fugen',
					'Mauerwerksfugen'
				],
				url: '/leistungen/brueckensanierung-und-korrosionsschutz'
			}
		]

		let currentImg = '/images/leistungen/beton-und-balkoninstandsetzung.jpg'

		if (activeItemIndex === 1) {
			currentImg =
				'/images/leistungen/horizontalsperren-beschichtung-kellerabdichtung.jpg'
		} else if (activeItemIndex === 2) {
			currentImg = '/images/leistungen/statische-spritzbetonverstaerkungen.jpg'
		} else if (activeItemIndex === 3) {
			currentImg = '/images/leistungen/bruecken-und-korrosionsschutz.jpg'
		}

		return (
			<div className="home-leistungen-container">
				<div className="home-leistungen-title">
					<p>Unsere Leistungen</p>
				</div>
				<div className="home-leistungen-slides-container">
					<div className="home-leistungen-slides-image-container">
						<div className="home-leistungen-slides-image-wrapper">
							<img src={currentImg} alt="leistungen" />
						</div>
						<div className="home-leistungen-slides-images-buttons-container">
							<div className="home-leistungen-slides-images-buttons-flex">
								<div
									className={`home-leistungen-slides-images-button ${
										activeItemIndex === 0 ? 'active' : ''
									}`}
									onClick={() => this.renderCurrentIndex(0)}
								>
									<p>
										Beton- und <br /> Balkoninstandsetzung
									</p>
								</div>
								<div
									className={`home-leistungen-slides-images-button ${
										activeItemIndex === 1 ? 'active' : ''
									}`}
									onClick={() => this.renderCurrentIndex(1)}
								>
									<p>
										Horizontalsperren, <br /> Beschichtung, <br />
										Kellerabdichtung
									</p>
								</div>
								<div
									className={`home-leistungen-slides-images-button ${
										activeItemIndex === 2 ? 'active' : ''
									}`}
									onClick={() => this.renderCurrentIndex(2)}
								>
									<p>
										Statische Spritzbeton- <br />
										verstärkungen, Mauerwerk
									</p>
								</div>
								<div
									className={`home-leistungen-slides-images-button ${
										activeItemIndex === 3 ? 'active' : ''
									}`}
									onClick={() => this.renderCurrentIndex(3)}
								>
									<p>
										Brückensanierung und <br />
										Korrosionsschutz
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="home-leistungen-slides-description-container">
						{this.createDisplayTexts(displayTexts)}
					</div>
				</div>
			</div>
		)
	}
}

export default HomeLeistungen
