import React from 'react'
import { Link } from 'react-router-dom'

import Navigation from './Navigation'
import MobileHeader from './MobileHeader'
import './layout.css'

const header = () => {
	return (
		<>
			<MobileHeader />
			<header className="header-desktop-only">
				<div className="lower-header-container">
					<div className="lower-header-logo-container">
						<Link to="/">
							<img
								src="/images/header/logo_header.png"
								alt="OS Betonschutz Logo"
							/>
						</Link>
					</div>
					<div className="lower-header-infos-container">
						<div className="lower-header-logo-info">
							<img
								src="/images/icons/icon_location.png"
								alt="Standort"
								style={{ width: '40px', height: '45px', paddingBottom: '4px' }}
							/>
							<div className="lower-header-logo-info-text-container">
								<p className="lower-header-logo-info-title">Besuchen Sie uns</p>
								<p className="lower-header-logo-info-text">
									Lausitzer Str. 3-5, 68775 Ketsch
								</p>
							</div>
						</div>
						<div className="lower-header-logo-info">
							<img src="/images/icons/icon_telefon.png" alt="Telefon" />
							<div className="lower-header-logo-info-text-container">
								<p className="lower-header-logo-info-title">Rufen Sie an</p>
								<p className="lower-header-logo-info-text">
									<a
										href="tel:+496202702936"
										target="_blank"
										rel="noreferrer noopener"
									>
										(0 62 02) 70 29 36
									</a>
								</p>
							</div>
						</div>
						<div className="lower-header-logo-info">
							<img
								src="/images/icons/icon_brief.png"
								style={{ height: '35px' }}
								alt="Mail"
							/>
							<div
								className="lower-header-logo-info-text-container"
								style={{ marginRight: '0px' }}
							>
								<p className="lower-header-logo-info-title">
									Schreiben Sie uns
								</p>
								<a
									href="mailto:info@os-betonschutz.de"
									target="_blank"
									rel="noreferrer noopener"
								>
									<p className="lower-header-logo-info-text">
										info@os-betonschutz.de
									</p>
								</a>
							</div>
						</div>
					</div>
				</div>
				<Navigation />
			</header>
		</>
	)
}

export default header
