import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie-banner'
import { postContact } from '../../../actions/contact'

class HomeKontakt extends Component {
	state = {
		name: '',
		phone: '',
		email: '',
		message: ''
	}

	handleInputChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	handleFormSubmit = event => {
		event.preventDefault()
		const data = { ...this.state }
		this.props.postContact(data)
	}

	render() {
		const cookies = new Cookies()

		let formInputs = (
			<form
				className="home-kontakt-info-inputs"
				onSubmit={this.handleFormSubmit}
			>
				<label className="home-kontakt-info-inputs-label">
					<div className="home-kontakt-info-inputs-label-img-container">
						<img
							src="/images/kontakt/person_icon_kontakt.png"
							alt="icon"
							htmlFor="name"
						/>
					</div>
					Ihr Name*
				</label>
				<input
					name="name"
					type="text"
					required
					onChange={this.handleInputChange}
				/>
				<label className="home-kontakt-info-inputs-label">
					<div className="home-kontakt-info-inputs-label-img-container">
						<img
							src="/images/kontakt/icon_telefon_kontakt.png"
							alt="icon"
							htmlFor="name"
						/>
					</div>
					Telefonnummer*
				</label>
				<input
					name="phone"
					type="text"
					required
					onChange={this.handleInputChange}
				/>
				<label className="home-kontakt-info-inputs-label">
					<div className="home-kontakt-info-inputs-label-img-container">
						<img
							src="/images/kontakt/person_icon_kontakt.png"
							alt="icon"
							htmlFor="name"
						/>
					</div>
					Ihre E-Mail Adresse*
				</label>
				<input
					name="email"
					type="email"
					required
					onChange={this.handleInputChange}
				/>
				<label className="home-kontakt-info-inputs-label">
					<div className="home-kontakt-info-inputs-label-img-container">
						<img
							src="/images/kontakt/person_icon_kontakt.png"
							alt="icon"
							htmlFor="name"
						/>
					</div>
					Ihr Anliegen*
				</label>
				<input
					name="message"
					type="text"
					required
					onChange={this.handleInputChange}
				/>
				<div className="home-kontakt-submit">
					<button type="submit">Senden</button>
				</div>
			</form>
		)

		if (this.props.contact.received) {
			formInputs = (
				<div className="home-kontakt-info-inputs">
					<div className="home-kontakt-requests-received">
						Vielen Dank für Ihre Anfrage!
					</div>
				</div>
			)
		}

		return (
			<div className="home-kontakt">
				<p className="home-kontakt-title">Kontaktieren Sie uns</p>
				<p className="home-kontakt-subtitle">
					MIT UNS HABEN SIE EINEN EXPERTEN GEFUNDEN
				</p>
				<div className="home-kontakt-divider"></div>
				<div className="home-kontakt-info">
					<div className="home-kontakt-info-address">
						<p className="home-kontakt-info-address-title">
							Vereinbaren Sie gerne mit uns ein kostenloses Erstgespräch.
						</p>
						<div className="home-kontakt-info-address-section">
							<div className="home-kontakt-info-address-section-img-container">
								<img src="/images/kontakt/icon_map_kontakt.png" alt="icon" />
							</div>
							<p>
								O + S Betonschutz GmbH <br />
								Lausitzer Str. 3-5, 68775 Ketsch
							</p>
						</div>
						<div className="home-kontakt-info-address-section">
							<div className="home-kontakt-info-address-section-img-container">
								<img
									src="/images/kontakt/icon_telefon_kontaktdaten.png"
									alt="icon"
								/>
							</div>
							<a
								href="tel:+496202702936"
								target="_blank"
								rel="noreferrer noopener"
							>
								<p>Tel. (0 62 02) 70 29 36</p>
							</a>
						</div>
						<div className="home-kontakt-info-address-section">
							<div className="home-kontakt-info-address-section-img-container">
								<img src="/images/kontakt/icon_webseite.png" alt="icon" />
							</div>
							<a
								href="mailto:info@os-betonschutz.de"
								target="_blank"
								rel="noreferrer noopener"
							>
								<p>info@os-betonschutz.de</p>
							</a>
						</div>
					</div>
					<div className="home-kontakt-info-image">
						{cookies.get('cookie-accepted') &&
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2599.0416639930645!2d8.528151316063596!3d49.35135997933893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797b78c258964b3%3A0x285fced34d08dc57!2sO%20%2B%20S%20Betonschutz%20GmbH!5e0!3m2!1sde!2sde!4v1582110924845!5m2!1sde!2sde"
								width="600"
								height="450"
								frameBorder="0"
								style={{ border: 0 }}
								allowFullScreen=""
							></iframe>
						}
					</div>
					{formInputs}
				</div>
				<div className="home-kontakt-info-image kontakt-mobile-only">
					{cookies.get('cookie-accepted') &&
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2599.0416639930645!2d8.528151316063596!3d49.35135997933893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797b78c258964b3%3A0x285fced34d08dc57!2sO%20%2B%20S%20Betonschutz%20GmbH!5e0!3m2!1sde!2sde!4v1582110924845!5m2!1sde!2sde"
							width="600"
							height="450"
							frameBorder="0"
							style={{ border: 0 }}
							allowFullScreen=""
						></iframe>
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		contact: state.contact
	}
}

export default connect(mapStateToProps, { postContact })(HomeKontakt)
