import React from 'react'

class Unternehmen extends React.Component {
	render() {
		return (
			<div className="unternehmen">
				<div className="home-banner-lower">
					<div
						className="home-banner-lower-title"
						style={{ marginTop: '40px' }}
					>
						Unternehmen
					</div>
					<div
						className="home-banner-lower-subtitle"
						style={{ marginTop: '30px' }}
					>
						DAS UNTERNEHMEN
					</div>
					<div className="home-banner-lower-divider"></div>
					<div className="home-banner-lower-description">
						Als mittelständisches, vorwiegend regional tätiges Unternehmen des
						Bauhauptgewerbes, sind wir Mitglied der Bauberufsgenossenschaft
						Karlsruhe sowie Mitglied der Landesgütegemeinschaft für
						Betonerhaltung und Betoninstandsetzung Baden – Württemberg. Darüber
						hinaus trägt unser Unternehmen das RAL-Gütezeichen Instandsetzung
						von Betonbauwerken. Unserem Betrieb gehören neben zwei Meistern und
						vier Vorarbeitern (alle mit gültigem S.I.V.V. Schein) zusätzlich
						vier Spezialfacharbeiter an (darunter drei mit SPCC
						Düsenführerschein).
					</div>
					<div
						className="home-banner-lower-subtitle"
						style={{ marginTop: '30px' }}
					>
						Karriere
					</div>
					<div className="home-banner-lower-divider"></div>
					<div className="home-banner-lower-description">
						<img src="/images/unternehmen/OS-Vorarbeiter.png" />
					</div>
					<div
						className="home-banner-lower-greetings"
						style={{ marginTop: '40px' }}
					>
						Wir freuen uns auf Sie
					</div>
				</div>
			</div>
		)
	}
}

export default Unternehmen
