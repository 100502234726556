import React from 'react'
import { Route, Switch, Router, Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import CookieBanner, { Cookies } from 'react-cookie-banner'

import ScrollToTop from './hooks/ScrollToTop'
import Layout from './layout'
import Home from './pages/Home'
import Kontakt from './pages/Kontakt'
import Leistungen from './pages/Leistungen'
import LeistungenDetail from './pages/Leistungen/LeistungenDetail'
import Unternehmen from './pages/Unternehmen'
import Referenzen from './pages/Referenzen'
import Impressum from './pages/Impressum'
import Datenschutz from './pages/Datenschutz'

function App() {
	const history = createBrowserHistory()
	const cookies = new Cookies()

	if (cookies.get('cookie-accepted')) {
		ReactGA.initialize('UA-157882336-1')
		ReactGA.ga('create', 'UA-157882336-1', 'auto')
		ReactGA.ga('send', 'pageview', window.location.pathname)

		history.listen(location => {
			ReactGA.ga('send', 'pageview', location.pathname)
		})
	}

	return (
		<Router history={history}>
			<ScrollToTop>
				<Layout>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/unternehmen" component={Unternehmen} />
						<Route exact path="/leistungen" component={Leistungen} />
						<Route
							exact
							path="/leistungen/:category"
							component={LeistungenDetail}
						/>
						<Route exact path="/referenzen" component={Referenzen} />
						<Route exact path="/kontakt" component={Kontakt} />
						<Route exact path="/impressum" component={Impressum} />
						<Route exact path="/datenschutz" component={Datenschutz} />
					</Switch>
				</Layout>
				<CookieBanner
					message="Diese Website nutzt Cookies, um bestmögliche Funktionalität bieten zu können."
					link={<Link to="/datenschutz">Mehr Infos über Datenschutz</Link>}
					buttonMessage="Ok, verstanden"
					disableStyle={true}
					onAccept={() => {
						window.location.reload()
					}}
					className="cookie-banner"
					cookie="cookie-accepted"
					dismissOnScroll={false}
					dismissOnClick={false}
				/>
			</ScrollToTop>
		</Router>
	)
}

export default App
