import { combineReducers } from 'redux'

const contactReducer = (state = { received: false }, action) => {
	switch (action.type) {
		case 'POST_CONTACT':
			return { ...state, received: true }
		default:
			return state
	}
}

export default combineReducers({ contact: contactReducer })
