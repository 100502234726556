import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Navigation extends Component {
	render() {
		return (
			<div className="header-navigation-container">
				<div className="header-navigation-inside">
					<div className="header-navigation-links">
						<Link to="/">Startseite</Link>
						<div>|</div>
						<Link to="/unternehmen">Unternehmen</Link>
						<div>|</div>
						<Link to="/leistungen">Leistungen</Link>
						<div>|</div>
						<Link to="/referenzen">Referenzen</Link>
						<div>|</div>
						<Link to="/kontakt">Kontakt</Link>
					</div>
					<div className="header-facebook-container">
						<a
							target="_blank"
							rel="noreferrer noopener"
							href="https://www.facebook.com/pages/category/Construction-Company/OS-Betonschutz-GmbH-170853806816640/"
						>
							<img
								src="/images/header/navigation/facebookicon.png"
								alt="facebook icon"
							/>
						</a>
					</div>
				</div>
			</div>
		)
	}
}

export default Navigation
