import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import HomeBanner from './HomeBanner'
import HomeLeistungen from './HomeLeistungen'
import HomeKontakt from './HomeKontakt'

class Home extends Component {
	render() {
		return (
			<>
				<Helmet>
					<title>
						Betonschäden, Balkonschäden, Betonkosmetik, Balkone, Brücken,
						Brandschäden, Heidelberg, Mannheim - O+S Betonschutz
					</title>
				</Helmet>
				<div className="home-container">
					<HomeBanner />
					<HomeLeistungen />
					<HomeKontakt />
				</div>
			</>
		)
	}
}

export default Home
