import React from 'react'
import { Helmet } from 'react-helmet'

import './impressum.css'

const impressum = () => {
	return (
		<>
			<Helmet>
				<title>
					Impressum | Betonschäden, Balkonschäden, Betonkosmetik, Balkone,
					Brücken, Brandschäden, Heidelberg, Mannheim - O+S Betonschutz
				</title>
			</Helmet>
			<div className="home-referenzen">
				<p className="home-referenzen-title">Impressum</p>
				<div className="home-referenzen-divider"></div>
				<div className="impressum-description">
					<div className="impressum-description-item">
						<p>
							<b>Angaben gemäß § 5 TMG:</b>
						</p>
						<br />
						<p>
							<b>O+S Betonschutz GmbH</b>
						</p>
						<p>
							Lausitzer Str. 3-5
							<br />
							68775 Ketsch
						</p>
						<br />
						<p>
							HRB Mannheim: 421318
							<br />
							USt-IdNr: DE 144289201
							<br />
							Geschäftsführer: Jürgen Ostler
						</p>
						<br />
						<br />
						<br />
						<p>
							<b>Inhaltlich Verantwortlich gemäß § 10 Absatz 3 MDStV:</b>
						</p>
						<br />
						<p>Jürgen Ostler (Anschrift wie oben)</p>
						<br />
						<br />
						<br />
						<p>
							<b>Design und Programmierung</b>
						</p>
						<br />
						<p>Dakitec GmbH<br />
						Agentur für digitalen Vorsprung
						</p>
					</div>
					<div className="impressum-description-item">
						<p>
							<b>Kontakt:</b>
						</p>
						<br />
						<p>Tel. (0 62 02) 70 29 36</p>
						<p>Fax (0 62 02) 70 29 37</p>
						<p>
							Mail:{' '}
							<a
								href="mailto:info@os-betonschutz.de"
								target="_blank"
								rel="noreferrer noopener"
							>
								info@os-betonschutz.de
							</a>
						</p>
						<p>
							Web:{' '}
							<a
								href="https://os-betonschutz.de/"
								target="_blank"
								rel="noreferrer noopener"
							>
								www.os-betonschutz.de
							</a>
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default impressum
