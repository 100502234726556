import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import HomeKontakt from '../Home/HomeKontakt'

class Kontakt extends Component {
	render() {
		return (
			<>
				<Helmet>
					<title>
						Kontakt | Betonschäden, Balkonschäden, Betonkosmetik, Balkone,
						Brücken, Brandschäden, Heidelberg, Mannheim - O+S Betonschutz
					</title>
				</Helmet>
				<div className="home-container">
					<HomeKontakt />
				</div>
			</>
		)
	}
}

export default Kontakt
