import requests from '../utils/requests'

export const postContact = data => async dispatch => {
	const formData = new FormData()

	formData.set('name', data.name)
	formData.set('phone', data.phone)
	formData.set('email', data.email)
	formData.set('message', data.message)

	const response = await requests.get(
		`/contact_me.php?name=${data.name}&phone=${data.phone}&email=${data.email}&message=${data.message}`
	)
	dispatch({ type: 'POST_CONTACT', payload: response })
}
