import React, { Component } from 'react'

import Slider from '../../shared/Slider'

import './home.css'

class HomeBanner extends Component {
	state = {
		currentActiveSlide: 0
	}

	createSliderPictures = pictures => {
		return pictures.map(picture => {
			return (
				<div className="home-banner-image-container" key={picture.src}>
					<img
						className="home-banner-slider-item"
						src={picture.src}
						alt="slide"
					/>
					{picture.element}
				</div>
			)
		})
	}
	render() {
		const { currentActiveSlide } = this.state

		const sliderPictures = this.createSliderPictures([
			{
				src: '/images/home/banner/1.JPG',
				element: (
					<div
						className={`home-banner-upper-image-text ${
							currentActiveSlide === 0 ? 'active' : ''
						}`}
					>
						<p className="bold">Willkommen bei</p>
						<p className="bold">O + S Betonschutz</p>
					</div>
				)
			},
			{
				src: '/images/home/banner/2.JPG',
				element: (
					<div
						className={`home-banner-upper-image-text ${
							currentActiveSlide === 1 ? 'active' : ''
						}`}
					>
						<p className="bold">Betoninstandsetzung</p>
					</div>
				)
			},
			{
				src: '/images/home/banner/3.JPG',
				element: (
					<div
						className={`home-banner-upper-image-text ${
							currentActiveSlide === 2 ? 'active' : ''
						}`}
					>
						<p className="bold">Beschichtung</p>
					</div>
				)
			},
			{
				src: '/images/home/banner/4.JPG',
				element: (
					<div
						className={`home-banner-upper-image-text ${
							currentActiveSlide === 3 ? 'active' : ''
						}`}
					>
						<p className="bold">Balkoninstandsetzung</p>
					</div>
				)
			},
			{
				src: '/images/home/banner/5.JPG',
				element: (
					<div
						className={`home-banner-upper-image-text ${
							currentActiveSlide === 4 ? 'active' : ''
						}`}
					>
						<p className="bold">Brückensanierung</p>
					</div>
				)
			},
			{
				src: '/images/home/banner/6.jpg',
				element: (
					<div
						className={`home-banner-upper-image-text ${
							currentActiveSlide === 5 ? 'active' : ''
						}`}
					>
						<p className="bold">Spritzbeton</p>
					</div>
				)
			}
		])

		const sliderSettings = {
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 3000,
			afterChange: current => this.setState({ currentActiveSlide: current })
		}

		return (
			<div className="home-banner-container">
				{/* Upper Section */}
				<div className="home-banner-upper">
					<div className="home-banner-upper-container">
						<div className="home-banner-upper-image">
							{/* <div className="home-banner-upper-image-container">
								<img src="/images/home/banner/banner-image.jpg" alt="banner" />
							</div> */}
							<div className="home-referenzen-slider">
								<Slider
									sliderPictureElements={sliderPictures}
									settings={sliderSettings}
								/>
							</div>
						</div>
					</div>
				</div>
				{/* Lower Section */}
				<div className="home-banner-lower">
					<div className="home-banner-lower-subtitle">
						Langfristige Bauwerkserhaltung durch Instandsetzung und Abdichtung
						nach gültigen Normen
					</div>
					<div className="home-banner-lower-divider"></div>
					<div
						className="home-banner-lower-greetings"
						style={{ marginTop: '20px' }}
					>
						Wir freuen uns auf Sie
					</div>
				</div>
			</div>
		)
	}
}

export default HomeBanner
