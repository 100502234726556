import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Carousel, { Modal, ModalGateway } from 'react-images'

import './leistungen.css'

class LeistungenDetail extends Component {
	state = { selectedIndex: 0, modalIsOpen: false, width: 0 }

	toggleModal = selectedIndex => {
		return this.setState(prevState => ({
			modalIsOpen: !prevState.modalIsOpen,
			selectedIndex
		}))
	}

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth })
	}

	componentDidMount() {
		this.updateWindowDimensions()
		window.addEventListener('resize', this.updateWindowDimensions)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions)
	}

	render() {
		const { category } = this.props.match.params
		const { modalIsOpen, selectedIndex, width } = this.state
		let title = ''
		let images

		if (category === 'brueckensanierung-und-korrosionsschutz') {
			images = [
				{ src: '/images/leistungen/brueckensanierung/120110531083804.JPG' },
				{ src: '/images/leistungen/brueckensanierung/IMGP0276.JPG' },
				{ src: '/images/leistungen/brueckensanierung/P1000765.JPG' },
				{ src: '/images/leistungen/brueckensanierung/2Bild 024.jpg' },
				{ src: '/images/leistungen/brueckensanierung/IMGP0278.JPG' },
				{ src: '/images/leistungen/brueckensanierung/P1020811.JPG' },
				{ src: '/images/leistungen/brueckensanierung/3Bild 011.jpg' },
				{ src: '/images/leistungen/brueckensanierung/IMG_0592.jpg' },
				{ src: '/images/leistungen/brueckensanierung/P1020843.JPG' },
				{ src: '/images/leistungen/brueckensanierung/4Bild 019.jpg' },
				{ src: '/images/leistungen/brueckensanierung/IMG_2238.jpg' },
				{ src: '/images/leistungen/brueckensanierung/RIMG0002.JPG' },
				{ src: '/images/leistungen/brueckensanierung/Bild 056.jpg' },
				{ src: '/images/leistungen/brueckensanierung/IMG_2329.jpg' },
				{ src: '/images/leistungen/brueckensanierung/RIMG0254 - Kopie.JPG' },
				{ src: '/images/leistungen/brueckensanierung/Bild 095.jpg' },
				{ src: '/images/leistungen/brueckensanierung/IMG_2425.jpg' },
				{ src: '/images/leistungen/brueckensanierung/RIMG1436.JPG' },
				{ src: '/images/leistungen/brueckensanierung/DSCN0582.JPG' },
				{ src: '/images/leistungen/brueckensanierung/IMG_4838.JPG' },
				{ src: '/images/leistungen/brueckensanierung/Rimg0002_2.jpg' },
				{ src: '/images/leistungen/brueckensanierung/DSCN0723.JPG' },
				{ src: '/images/leistungen/brueckensanierung/IMG_4839.JPG' },
				{ src: '/images/leistungen/brueckensanierung/Rimg0005_2.jpg' }
			]
			title = 'BRÜCKENSANIERUNG UND KORROSIONSSCHUTZ'
		} else if (category === 'statische-spritzbetonverstaerkungen') {
			images = [
				{ src: '/images/leistungen/spritzbeton/01-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/02-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/03-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/IMG_0707-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/IMG_20191212_114020-min.jpg' },
				{ src: '/images/leistungen/spritzbeton/Bild 028 - Kopie-min.jpg' },
				{ src: '/images/leistungen/spritzbeton/IMG_4777-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/Bild 076 - Kopie-min.jpg' },
				{ src: '/images/leistungen/spritzbeton/P1010596-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/DSCN0379-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/P1010876-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/DSCN1819 - Kopie-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/P1020670-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/DSCN2221 - Kopie-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/P1040532-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/DSCN5551-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/P1040617-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/IMGP1327-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/P1040697-min.JPG' },
				{ src: '/images/leistungen/spritzbeton/P1050653.JPG' },
				{ src: '/images/leistungen/spritzbeton/P1060066.JPG' },
				{ src: '/images/leistungen/spritzbeton/P1070482.JPG' },
				{ src: '/images/leistungen/spritzbeton/RIMG0003_2.JPG' },
				{ src: '/images/leistungen/spritzbeton/IMGP2738-min.JPG' }
			]
			title = 'STATISCHE SPRITZBETONVERSTÄRKUNGEN, MAUERWERK'
		} else if (category === 'beton-und-balkoninstandsetzung') {
			images = [
				{ src: '/images/leistungen/beton_und_balkon/1P1010909.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/2DSC04202.JPG' },
				{
					src: '/images/leistungen/beton_und_balkon/3IMG-20191108-WA0001.jpeg'
				},
				{ src: '/images/leistungen/beton_und_balkon/4DSC00157.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/5IMG_1145.jpg' },
				{ src: '/images/leistungen/beton_und_balkon/6IMG_5466.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/7IMG_5472.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/8IMG_7771.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/9kassel giebel 2.jpg' },
				{ src: '/images/leistungen/beton_und_balkon/10DSC03260.jpg' },
				{ src: '/images/leistungen/beton_und_balkon/11IMGP2323.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/12karlsruhe 2.jpg' },
				{ src: '/images/leistungen/beton_und_balkon/13Bild 014_2.jpg' },
				{ src: '/images/leistungen/beton_und_balkon/14DSC02511.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/15DSC05511.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/16F02_Fassade Süd.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/17IMG_1625.jpg' },
				{ src: '/images/leistungen/beton_und_balkon/18IMGP1213.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/19IMGP1235.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/20ladenburg 2.jpg' },
				{ src: '/images/leistungen/beton_und_balkon/21P1020646.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/22P1010995.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/23P1020745.JPG' },
				{ src: '/images/leistungen/beton_und_balkon/24P1050111.JPG' }
			]
			title = 'BETON- UND BALKONINSTANDSETZUNG'
		} else if (category === 'horizontalsperren-beschichtung-kellerabdichtung') {
			images = [
				{ src: '/images/leistungen/horizontalsperren/1DSC04955-min.JPG' },
				{
					src: '/images/leistungen/horizontalsperren/2DSC04232 - Kopie-min.JPG'
				},
				{
					src:
						'/images/leistungen/horizontalsperren/3IMG_20190528_105018-min.jpg'
				},
				{ src: '/images/leistungen/horizontalsperren/4DSC04982-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/5IMG_0009-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/6DSCN2508-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/7P1010020-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/8P1060893-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/9P1060265-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/10P1060013-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/11P1060019-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/12IMGP2304-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/13P1060950-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/14IMGP0574.JPG' },
				{ src: '/images/leistungen/horizontalsperren/15IMGP0843-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/16IMGP0435.JPG' },
				{ src: '/images/leistungen/horizontalsperren/17IMGP2347.JPG' },
				{ src: '/images/leistungen/horizontalsperren/18P1000932-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/19P1000983-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/2012P1060162-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/21DSC00192-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/22DSC04789-min.JPG' },
				{ src: '/images/leistungen/horizontalsperren/23IMGP0680 - Kopie.JPG' },
				{ src: '/images/leistungen/horizontalsperren/24P1010688-min.JPG' }
			]
			title = 'HORIZONTALSPERREN, BESCHICHTUNG, KELLERABDICHTUNG'
		}

		let content = (
			<div>
				<div className="home-leistungen-slides-description-title">
					{title}
					<div className="home-referenzen-buttons leistungen">
						<Link className="leistungenBack" to="/leistungen">
							← Zu den Leistungen
						</Link>
					</div>
				</div>
				<div className="leistungen-images">
					<div className="leistungen-images-children">
						{images.map((image, index) => (
							<div
								className="leistungen-image-container"
								key={image.src}
								onClick={() => this.toggleModal(index)}
							>
								<img src={image.src} alt="Slide" />
							</div>
						))}
					</div>
				</div>
				<ModalGateway>
					{modalIsOpen ? (
						<Modal onClose={this.toggleModal}>
							<Carousel
								currentIndex={selectedIndex}
								frameProps={{ autoSize: 'height' }}
								views={images}
							/>
						</Modal>
					) : null}
				</ModalGateway>
			</div>
		)

		if (width < 500) {
			content = (
				<div>
					<div className="home-leistungen-slides-description-title">
						{title}
						<div className="home-referenzen-buttons leistungen">
							<Link className="leistungenBack" to="/leistungen">
								← Zu den Leistungen
							</Link>
						</div>
					</div>
					<div className="leistungen-images">
						<div className="leistungen-images-children">
							{images.map((image, index) => (
								<div
									className="leistungen-image-container"
									key={image.src}
									onClick={() => this.toggleModal(index)}
								>
									<img src={image.src} alt="Slide" />
								</div>
							))}
						</div>
					</div>
					<ModalGateway>
						{modalIsOpen ? (
							<Modal onClose={this.toggleModal}>
								<Carousel currentIndex={selectedIndex} views={images} />
							</Modal>
						) : null}
					</ModalGateway>
				</div>
			)
		}

		return (
			<>
				<Helmet>
					<title>
						Leistungen | Betonschäden, Balkonschäden, Betonkosmetik, Balkone,
						Brücken, Brandschäden, Heidelberg, Mannheim - O+S Betonschutz
					</title>
				</Helmet>
				<div className="leistungen-detail">{content}</div>
			</>
		)
	}
}

export default LeistungenDetail
