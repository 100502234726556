import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './layout.css'

class MobileHeader extends Component {
	state = {
		mobileMenuActive: false
	}

	createHeaderLinks = () => {
		const headerLinks = [
			{ title: 'startseite', to: '/' },
			{ title: 'unternehmen', to: '/unternehmen' },
			{ title: 'leistungen', to: '/leistungen' },
			{ title: 'referenzen', to: '/referenzen' },
			{ title: 'karriere', to: '/unternehmen' },
			{ title: 'kontakt', to: '/kontakt' },
			{ title: 'impressum', to: '/impressum' },
			{ title: 'datenschutz', to: '/datenschutz' }
		]

		const HeaderElements = headerLinks.map((link, index) => {
			if (index === headerLinks.length - 1) {
				return (
					<React.Fragment key={link.title}>
						<div className="mobile-menu-content-item">
							<Link
								to={link.to}
								key={link.title}
								onClick={() => this.openMobileMenu(false)}
							>
								{link.title}
							</Link>
						</div>
					</React.Fragment>
				)
			}

			return (
				<React.Fragment key={link.title}>
					<div className="mobile-menu-content-item">
						<Link
							to={link.to}
							key={link.title}
							onClick={() => this.openMobileMenu(false)}
						>
							{link.title}
						</Link>
					</div>

					{/* Extra vertical line except for the last Link */}
					<div className="mobile-menu-content-divider" />
				</React.Fragment>
			)
		})
		return (
			<>
				{HeaderElements}
				<div className="header-mobile-facebook-container">
					<a
						target="_blank"
						rel="noreferrer noopener"
						href="https://www.facebook.com/pages/category/Construction-Company/OS-Betonschutz-GmbH-170853806816640/"
					>
						<img
							src="/images/header/navigation/facebookicon.png"
							alt="facebook icon"
						/>
					</a>
				</div>
			</>
		)
	}

	openMobileMenu = close => {
		if (close) {
			return this.setState({
				mobileMenuActive: false
			})
		}

		return this.setState((prevState, props) => ({
			mobileMenuActive: !prevState.mobileMenuActive
		}))
	}

	render() {
		let mobileMenuContent = (
			<div className="mobile-menu-content-container">
				{this.createHeaderLinks(true)}
			</div>
		)
		let hamburgerMenuClass = 'header-mobile-hamburger'
		let mobileHeaderClass = 'header'

		if (this.state.mobileMenuActive) {
			hamburgerMenuClass += ' checked'
			mobileMenuContent = (
				<div className="mobile-menu-content-container checked">
					{this.createHeaderLinks(true)}
				</div>
			)
			mobileHeaderClass += ' checked'
		}

		return (
			<header className="header-mobile-only">
				<div className={mobileHeaderClass}>
					<div className="header-mobile-container">
						<Link to="/">
							<img
								className="header-mobile-logo"
								src="/images/header/OSBLogoWhite.png"
								alt="logo"
								onClick={() => this.openMobileMenu(true)}
							/>
						</Link>
						<div className="header-mobile-hamburger-container">
							<div
								className={hamburgerMenuClass}
								onClick={() => this.openMobileMenu(false)}
							>
								<span className="header-mobile-hamburger-line" />
								<span className="header-mobile-hamburger-line" />
								<span className="header-mobile-hamburger-line" />
							</div>
						</div>
					</div>
				</div>
				{mobileMenuContent}
			</header>
		)
	}
}

export default MobileHeader
