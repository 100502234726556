import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import HomeLeistungen from '../Home/HomeLeistungen'

class Leistungen extends Component {
	render() {
		return (
			<>
				<Helmet>
					<title>
						Leistungen | Betonschäden, Balkonschäden, Betonkosmetik, Balkone,
						Brücken, Brandschäden, Heidelberg, Mannheim - O+S Betonschutz
					</title>
				</Helmet>
				<div className="home-container">
					<div className="home-banner-lower">
						<div
							className="home-banner-lower-title"
							style={{ marginTop: '40px' }}
						>
							Leistungen
						</div>
						<div
							className="home-banner-lower-subtitle"
							style={{ marginTop: '30px' }}
						>
							Übersicht
						</div>
						<div className="home-banner-lower-divider"></div>
						<div
							className="home-banner-lower-description"
							style={{ marginBottom: '30px' }}
						>
							Arbeiten führen wir im privaten, gewerblichen und städtischen
							Rahmen, sowohl im Hoch-, Tief- und Ingenieurbau aus, auch als
							Generalunternehmer für verschiedene Gewerke. Gerne beraten wir Sie
							ausführlich zu Ihrem Bauvorhaben und informieren Sie kompetent
							über die Möglichkeiten, Ihr Projekt umzusetzen.
						</div>
					</div>
					<HomeLeistungen />
				</div>
			</>
		)
	}
}

export default Leistungen
