import React, { Component } from 'react'
import Helmet from 'react-helmet'

import './referenzen.css'

class Referenzen extends Component {
	render() {
		const wohnungIndustrieGewerbebau = [
			{
				name: 'RNV Mannheim, Rampenbeschichtung und Parkdeck',
				type: 'Auftrag',
				price: '270 T€'
			},

			{
				name: 'BBS, Heinrich-Lanzschule Mannheim, Fassade',
				type: 'Auftrag',
				price: '74	T€'
			},

			{
				name: 'Dom in Worms, Betonschäden und Abdichtung',
				type: 'Auftrag',
				price: '44	T€'
			},

			{
				name: 'Link Natursteine Mannheim, Verladerampe',
				type: 'Auftrag',
				price: '54	T€'
			},

			{
				name: 'Staufenbergschule Heidelberg, Rippendecken',
				type: 'Auftrag',
				price: '24	T€'
			},

			{
				name: 'Feuerwehrhaus Hessheim, Fassade',
				type: 'Auftrag',
				price: '8 T€'
			},

			{
				name: 'Volkswohl Karlsruhe, Tiefgarageninstandsetzung',
				type: 'Auftrag',
				price: '148 T€'
			},

			{
				name: 'HeidelbergerCement Werk Leimen, Betonschäden',
				type: 'Auftrag',
				price: '24	T€'
			},

			{
				name: 'Luisenpark Mannheim, Brunnenbeschichtung',
				type: 'Auftrag',
				price: '16	T€'
			},

			{
				name: 'Bolandenschule Waghäusel, Fassade',
				type: 'Auftrag',
				price: '34	T€'
			},

			{
				name: 'Freilichtbühne Mannheim, Bühnenplätze',
				type: 'Auftrag',
				price: '17	T€'
			},

			{
				name: 'HeidelbergerCement Werk Leimen, Betonsilo',
				type: 'Auftrag',
				price: '54	T€'
			},

			{
				name: 'Betonfassade Relief, Rathaus in Walldorf',
				type: 'Auftrag',
				price: '89	T€'
			},

			{
				name: 'Tiefgarage Frankfurt, Am Henningerturm',
				type: 'Auftrag',
				price: '24	T€'
			},

			{
				name: 'Beschichtung, Laubengänge Reichelsheim',
				type: 'Auftrag',
				price: '36	T€'
			},

			{
				name: 'Tiefgarage OS 11, Paulusheim Bruchsal',
				type: 'Auftrag',
				price: '16	T€'
			},

			{
				name: 'Tiefgarage Karlsruhe, Beschichtung der Sockel',
				type: 'Auftrag',
				price: '38	T€'
			},

			{
				name: 'GBG Mannheim, Balkone, Hochuferstr.',
				type: 'Auftrag',
				price: '90	T€'
			},

			{
				name: 'Regenüberlaufbecken, Karlsdorf, Beschichtung',
				type: 'Auftrag',
				price: '40	T€'
			},

			{
				name: 'Balkone Kaiserslautern, Sonnenberg, 3 Gebäude',
				type: 'Auftrag',
				price: '180 T€'
			},

			{
				name: 'GBG Mannheim, Balkone, Kronenburgstr.',
				type: 'Auftrag',
				price: '90	T€'
			},

			{
				name: 'Schlachthof Karlsruhe, Betonfassade',
				type: 'Auftrag',
				price: '25	T€'
			},

			{
				name: 'Plankstadt Wasserturm, Korrosionsschutz',
				type: 'Auftrag',
				price: '68	T€'
			},

			{
				name: 'Bellamar in Schwetzingen, Stützenverstärkung',
				type: 'Auftrag',
				price: '100 T€'
			},

			{
				name: 'Kläranlage in Ketsch, Beschichtung',
				type: 'Auftrag',
				price: '45	T€'
			},

			{
				name: 'Terrassenhaus in Karlsruhe, Fassade 6000 m²',
				type: 'Auftrag',
				price: '185 T€'
			},

			{
				name: 'Bürogebäude in Mannheim, Heinrich-Lanz-Str.',
				type: 'Auftrag',
				price: '45	T€'
			},

			{
				name: 'Schwimmhalle in Oberhausen-Rheinhausen',
				type: 'Auftrag',
				price: '70	T€'
			},

			{ name: 'Eremitage Schloss Waghäusel', type: 'Auftrag', price: '30	T€' },

			{
				name: 'Sommerbergbahn, Bad Wildbad Beschichtung',
				type: 'Auftrag',
				price: '10	T€'
			},

			{
				name: 'Balkone Plankstadt, Berlinerstr.',
				type: 'Auftrag',
				price: '15	T€'
			},

			{
				name: 'Balkone in Heidelberg, Langgewann',
				type: 'Auftrag',
				price: '70	T€'
			},

			{
				name: 'BAKW VT, in Mannheim, Brandschutz',
				type: 'Auftrag',
				price: '35	T€'
			},

			{
				name: 'Hochhaus in Hockenheim, Kollmerstr. 59',
				type: 'Auftrag',
				price: '220 T€'
			},

			{
				name: 'Filzfabrik am Hammelturm in Speyer',
				type: 'Auftrag',
				price: '25	T€'
			},

			{
				name: 'Hochhaus in Mannheim, Mainstr. 34',
				type: 'Auftrag',
				price: '60	T€'
			},

			{
				name: 'Pfalzbau  in Ludwigshafen, Bismarkstr. 116',
				type: 'Auftrag',
				price: '210 T€'
			},

			{
				name: 'Kirche St. Andreas in Edingen-Neckarhausen, Fichtenstr.',
				type: 'Auftrag',
				price: '55	T€'
			},

			{
				name: 'Parkhaus in Karlsruhe, Deutsche Bank, Schlossplatz 21',
				type: 'Auftrag',
				price: '45	T€'
			},

			{
				name: 'Wohnanlage in Heidelberg, Fritz-Frey-Str. 13',
				type: 'Auftrag',
				price: '265 T€'
			},

			{
				name: 'Polizeihochhaus in Baden-Baden an der B 500',
				type: 'Auftrag',
				price: '110 T€'
			}
		]

		const statischeSpritzbeton = [
			{
				name: 'Forst BW, Schembachbrücke Schwarzwald',
				type: 'Auftrag',
				price: '15 T€'
			},

			{
				name: 'Finger, Stockstadt, Brückenbeschichtung in Solingen',
				type: 'Auftrag',
				price: '5 T€'
			},

			{
				name: 'Gymnasium  Paulusheim, Bruchsal OS 11 Abdichtung',
				type: 'Auftrag',
				price: '12 T€'
			},

			{
				name: 'PZN Wiesloch, Geb. 37, Spritzbetonverstärkung',
				type: 'Auftrag',
				price: '138 T€'
			},

			{
				name: 'Schloss Bruchsal, Deckenverstärkung',
				type: 'Auftrag',
				price: '54 T€'
			},

			{
				name: 'Porsche Zuffenhausen, Vergelung Schacht',
				type: 'Auftrag',
				price: '5 T€'
			},

			{
				name: 'Balkone Karlsruhe, Adlerstr. Beschichtung',
				type: 'Auftrag',
				price: '10 T€'
			},

			{
				name: 'Spritzbeton Gebäude 26, PZN Wiesloch',
				type: 'Auftrag',
				price: '189 T€'
			},

			{
				name: 'Tiefgarage, SB Heidelberg-Rohrbach',
				type: 'Auftrag',
				price: '22 T€'
			},

			{
				name: 'Betonböden Bauhaus Ludwigshafen',
				type: 'Auftrag',
				price: '15 T€'
			},

			{
				name: 'DB Bahn, Hauptbahnhof Osthofen',
				type: 'Auftrag',
				price: '16 T€'
			},

			{
				name: 'Tiefgarage Risse, Emil-Nolde-Str., Ludwigshafen',
				type: 'Auftrag',
				price: '38 T€'
			},

			{
				name: 'Tiefgarage Henninger Turm, Frankfurt',
				type: 'Auftrag',
				price: '25 T€'
			},

			{
				name: 'DB Bahn, Hauptbahnhof Frankenthal',
				type: 'Auftrag',
				price: '18 T€'
			},

			{
				name: 'DB Bahn, Kopfbalken Heidelberg',
				type: 'Auftrag',
				price: '25 T€'
			},

			{
				name: 'Abfallwirtschaft KFW, MA-Käfertal, Kellerdecken',
				type: 'Auftrag',
				price: '85 T€'
			},

			{
				name: 'EMBL, Heidelberg, Vergelung der Laborräume',
				type: 'Auftrag',
				price: '35 T€'
			},

			{
				name: 'BFW, Bad Wildbad, Deckenschäden',
				type: 'Auftrag',
				price: '35 T€'
			},

			{
				name: 'Brücken Tonbachtal, Schwarzwald',
				type: 'Auftrag',
				price: '60 T€'
			},

			{
				name: 'Alter Schlachthof Karlsruhe, Decken',
				type: 'Auftrag',
				price: '65 T€'
			},

			{
				name: 'Reihenhäuser, Neue Hofgärten Ludwigshafen',
				type: 'Auftrag',
				price: '120 T€'
			},

			{
				name: 'UNI-Klinik Mannheim, Vergelung einer Tiefgarage',
				type: 'Auftrag',
				price: '18 T€'
			},

			{
				name: 'Brunnen in Schwetzingen, Wasserbecken beschichten',
				type: 'Auftrag',
				price: '20 T€'
			},

			{
				name: 'Kehl Werfthalle, Hafenstr. 26, Brandschaden',
				type: 'Auftrag',
				price: '55 T€'
			},

			{
				name: 'Maulbronn Grundbuchamt, Fassade',
				type: 'Auftrag',
				price: '30 T€'
			},

			{
				name: 'Bauhaus in Heidelberg, Betonschäden Parkdeck',
				type: 'Auftrag',
				price: '30 T€'
			},

			{
				name: 'Deckenverstärkung Pforzheim, Bleichstr.',
				type: 'Auftrag',
				price: '65 T€'
			},

			{
				name: 'Verpressen in Heidelberg, Im Schollengewann',
				type: 'Auftrag',
				price: '25 T€'
			},

			{ name: 'BFW Bad Wildbad, Spritzbeton', type: 'Auftrag', price: '60 T€' },

			{
				name: 'Tunnel Hirschhorn, Verpressen, Abdichten',
				type: 'Auftrag',
				price: '20 T€'
			},

			{
				name: 'Kleber Barracks, Kaiserslautern',
				type: 'Auftrag',
				price: '45 T€'
			},

			{
				name: 'Saukopftunnel Weinheim, Vergelung',
				type: 'Auftrag',
				price: '25 T€'
			},

			{
				name: 'Tiefgarage in Mannheim, Dalbergstr.',
				type: 'Auftrag',
				price: '40 T€'
			},

			{
				name: 'Parkdeck in Heidelberg - Ziegelhausen, Oberer Rainweg',
				type: 'Auftrag',
				price: '25 T€'
			},

			{
				name: 'Tiefgarage in Landau, Stadthausgasse 5',
				type: 'Auftrag',
				price: '10 T€'
			},

			{
				name: 'Brandschäden Heilbronn, Leingarten und Mannheim',
				type: 'Auftrag',
				price: '30 T€'
			},

			{
				name: 'Psychiatrisches Zentrum Wiesloch, Gebäude 05',
				type: 'Auftrag',
				price: '210	T€'
			},

			{
				name: 'Abdichtung, Hochbehälter Friedrichsbad in Baden-Baden',
				type: 'Auftrag',
				price: '55 T€'
			},

			{
				name: 'Heizkeller Steubenstraße in Heidelberg',
				type: 'Auftrag',
				price: '70 T€'
			},

			{ name: 'Hühnerlochwehr in Grötzingen', type: 'Auftrag', price: '50 T€' }
		]

		return (
			<>
				<Helmet>
					<title>
						Referenzen | Betonschäden, Balkonschäden, Betonkosmetik, Balkone,
						Brücken, Brandschäden, Heidelberg, Mannheim - O+S Betonschutz
					</title>
				</Helmet>
				<div className="referenzen-container">
					{/* <HomeReferenzen /> */}
					<p className="home-referenzen-title">Referenzen</p>
					<p className="home-referenzen-subtitle">Gehören auch Sie dazu</p>
					<div className="home-referenzen-divider"></div>
					<p className="home-referenzen-description">
						Wir führen Objekte in verschiedenen Größenordnungen aus. Dieser
						Auszug steht beispielhaft für unser umfassendes Leistungsspektrum.
					</p>
					<div className="referenzen-liste">
						<p className="referenzen-liste-title">
							Wohnungs-, Industrie- und Gewerbebau
						</p>
						<div className="referenzen-liste-container">
							{wohnungIndustrieGewerbebau.map(element => {
								return (
									<div
										className="referenzen-liste-items-container"
										key={element.name}
									>
										<p className="referenzen-liste-items-container-name">
											{element.name}
										</p>
										<p className="referenzen-liste-items-container-type">
											{element.type}
										</p>
										<p className="referenzen-liste-items-container-price">
											{element.price}
										</p>
									</div>
								)
							})}
						</div>
						<p className="referenzen-liste-title">
							Statische Spritzbetonverstärkung, Injektionen, Abdichtung, Brücken
						</p>
						<div className="referenzen-liste-container">
							{statischeSpritzbeton.map(element => {
								return (
									<div
										className="referenzen-liste-items-container"
										key={element.name}
									>
										<p className="referenzen-liste-items-container-name">
											{element.name}
										</p>
										<p className="referenzen-liste-items-container-type">
											{element.type}
										</p>
										<p className="referenzen-liste-items-container-price">
											{element.price}
										</p>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default Referenzen
