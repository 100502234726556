import React from 'react'
import { Link } from 'react-router-dom'

const footer = () => {
	return (
		<footer>
			<div className="upper-footer-container">
				<div className="upper-footer-logo-container">
					<img
						src="/images/footer/Logo_weiss.png"
						alt="OS Betonschutz Logo Weiss"
					/>
				</div>
				<div className="upper-footer-info-container">
					<div className="upper-footer-info-item">
						<p className="upper-footer-info-item-title">Kontakt</p>
						<div className="upper-footer-info-item-line"></div>
						<div className="upper-footer-info-item-content">
							<div className="upper-footer-info-item-content-section">
								<p>O + S Betonschutz GmbH</p>
								<p>Lausitzer Str. 3-5</p>
								<p> 68775 Ketsch</p>
							</div>
							<div className="upper-footer-info-item-content-section">
								<p>
									<a
										href="tel:+496202702936"
										target="_blank"
										rel="noreferrer noopener"
									>
										Tel (0 62 02) 70 29 36
									</a>
								</p>
								<p>Fax (0 62 02) 70 29 37</p>
								<p>
									<a
										href="mailto:info@os-betonschutz.de"
										target="_blank"
										rel="noreferrer noopener"
									>
										info@os-betonschutz.de
									</a>
								</p>
							</div>
						</div>
					</div>
					<div className="upper-footer-info-item">
						<p className="upper-footer-info-item-title">Navigation</p>
						<div className="upper-footer-info-item-line"></div>
						<div className="upper-footer-info-item-content">
							<div className="upper-footer-info-item-content-section navigation-margin">
								<p>
									<Link to="/">STARTSEITE</Link>
								</p>
								<p>
									<Link to="/unternehmen">UNTERNEHMEN</Link>
								</p>
								<p>
									<Link to="/leistungen">LEISTUNGEN</Link>
								</p>
								<p>
									<Link to="/referenzen">REFERENZEN</Link>
								</p>
							</div>
							<div className="upper-footer-info-item-content-section">
								<p>
									<Link to="/kontakt">KONTAKT</Link>
								</p>
								<p>
									<Link to="/impressum">IMPRESSUM</Link>
								</p>
								<p>
									<Link to="/datenschutz">DATENSCHUTZ</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="lower-footer-container">
				<p>© O+S Betonschutz GmbH {new Date().getFullYear()}</p>
			</div>
		</footer>
	)
}

export default footer
